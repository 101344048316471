<template>
    <div class="text-center mb-5">
        <p class="text-[16px] leading-[1] text-white">Din tid:</p>
        <p class="text-[18px] leading-[1.6] font-medium text-white text-center" v-html="time"></p>
    </div>
</template>

<script>
export default {
    name: 'Timer',
    props: {
        time: {
            type: String,
            required: true,
            default: '00:00:00'
        },
    },
}
</script>