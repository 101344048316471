<template>
    <div class="px-5 py-8 wrapper">
        <img class="w-full max-w-[297px] mx-auto mb-16" :src="logo">
        <div>
            <h1 class="heading-xl text-white font-bold text-center mb-4">Vem vet mest?</h1>
            <p class="body-md text-white text-center mb-8">Har du koll på Norrlands Bil, bilar i största allmänhet,
                eller
                kanske bara tur
                som en tok? Varför inte svara på vårt quiz och få möjlighet att vinna fina
                priser?</p>
        </div>

        <div class="bg-lightblue p-6 rounded-2xl -rotate-[4deg] mb-8">
            <p class="body-lg text-white text-center">Testet går på tid, så ta gärna en sväng i hallen först för att
                friska upp
                kunskaperna.
                Så snart du är redo är det bara att köra igång.
                Lycka till!</p>
        </div>
        <button @click="startQuiz" class="button">
            Sätt igång
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2633 7.53826H0.399475V5.89987H17.2632L12.9273 1.56397L14.0859 0.405457L20.3995 6.71908L14.0859 13.0327L12.9273 11.8742L17.2633 7.53826Z"
                    fill="#FBF5BE" />
            </svg>
        </button>
    </div>

</template>

<script>
import logo from '@assets/img/logotype.png'

export default {
    name: 'StartScreen',
    methods: {
        startQuiz() {
            this.$store.dispatch('updateTransition', 'slide-right')
            this.$router.push({ name: 'questions' })
        }
    },
    computed: {
        logo() {
            return logo
        }
    }
}
</script>
