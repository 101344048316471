<template>
    <div class="wrapper flex flex-col h-full pt-8">
        <img class="w-full max-w-[297px] mx-auto mb-16" :src="logo">
        <div class="px-5 mb-6">
            <h1 class="text-[36px] leading-[1.1] font-bold text-white text-center mb-5">Fyll i dina
                uppgifter för att få ditt resultat</h1>
            <p class="text-center text-white text-[18px] leading-[1.6]">Snyggt jobbat!
                Så snart du fyllt i dina uppgifter är du med och tävlar.
                Vi håller tummarna!</p>
        </div>

        <div class="bg-lightblue px-5 pt-8 pb-28 rounded-t-3xl mt-auto">
            <form @submit.prevent="submit">
                <div class="flex flex-col gap-2 mb-6">

                    <TextInput v-model="firstname" placeholder="Förnamn"></TextInput>
                    <p v-if="firstnameError" class="text-red">Fyll i ditt förnamn.</p>

                    <TextInput v-model="lastname" placeholder="Efternamn"></TextInput>
                    <p v-if="lastnameError" class="text-red">Fyll i ditt efternamn.</p>

                    <EmailInput v-model="email" placeholder="E-post"></EmailInput>
                    <p v-if="emailError" class="text-red">Fyll i din e-post.</p>

                    <label class="flex flex-row gap-2 items-center justify-center">
                        <input v-model="consent" type="checkbox">
                        <span class="text-white text-[18px] leading-[1.6]">Jag godkänner att mina uppgifter
                            sparas</span>
                    </label>
                </div>
                <Button text="Se resultat" :disabled="submitted" @click="showResults"></Button>
            </form>
        </div>
    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { questions } from '../data/questions'
import Button from './Button.vue'
import TextInput from './TextInput.vue'
import EmailInput from './EmailInput.vue'
import logo from '@assets/img/logotype.png'


export default {
    name: 'RegisterScreen',
    components: {
        Button,
        TextInput,
        EmailInput,
    },
    computed: {
        logo() {
            return logo
        }
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        let firstname = ref('')
        let lastname = ref('')
        let email = ref('')
        let consent = ref(false)
        let firstnameError = ref(false)
        let lastnameError = ref(false)
        let emailError = ref(false)
        let submitted = ref(false)

        function showResults() {
            submitted.value = true;

            firstnameError.value = !firstname.value;
            lastnameError.value = !lastname.value;
            emailError.value = !email.value;

            if (!firstnameError.value && !lastnameError.value && !emailError.value && consent.value) {
                store.commit('setFirstname', firstname.value)
                store.commit('setLastname', lastname.value)
                store.commit('setEmail', email.value)

                // Proceed with showing results
                saveResult();  // Assuming saveResult is the function to process results
            } else {
                submitted.value = false;
            }
        }

        function saveResult() {

            var data = {
                action: 'SaveResult',
                firstname: store.getters.firstname,
                lastname: store.getters.lastname,
                email: store.getters.email,
                finalscore: store.getters.finalScore,
                elapsedtime: store.getters.elapsedTime,
                answers: JSON.stringify(store.getters.answers),
            }

            // Send data to server
            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        //Next screen
                        store.commit('userId', data.userId)
                        getLeaderBoard()
                        router.push({ name: 'result' })
                    } else {
                        alert(data.message)  // Display error message
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })
        }

        function getLeaderBoard() {
            var data = {
                action: 'GetLeaderBoard',
                userId: store.getters.userId
            }

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        store.commit('leaderboard', data.leaderboard)
                        store.commit('isCurrentUserFirst', data.isCurrentUserFirst)
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        onMounted(() => {
            if (store.getters.answers.length < questions.length) {
                console.log('User has not completed the quiz, return to frontpage')
                router.push({ name: 'start' })
            }
        })

        return {
            showResults,
            saveResult,
            firstname,
            lastname,
            email,
            consent,
            firstnameError,
            lastnameError,
            emailError,
            submitted,
            questions
        }
    }
}
</script>