<template>
    <div class="wrapper flex flex-col h-full pt-5">
        <div class="px-5 mb-6">
            <ProgressBar :progress="progress" :total="questions.length"></ProgressBar>
            <Timer :time="elapsedTime"></Timer>
        </div>

        <transition name="fade">
            <div class="flex flex-col h-full" v-if="showQuestion" :key="currentQuestion.question">
                <div class="px-5 mb-6">
                    <h1 class="text-[24px] leading-[1.1] font-bold text-white text-center mb-5"
                        v-html="currentQuestion.question"></h1>
                    <img class="mx-auto max-w-[279px] w-full" v-if="currentQuestion.questionImage"
                        :src="currentQuestion.questionImage">
                    <p class="text-center text-white leading-[1.6]" v-if="currentQuestion.questionText"
                        v-html="currentQuestion.questionText"></p>
                </div>

                <div class="bg-lightblue px-5 pt-8 pb-28 rounded-t-3xl mt-auto">
                    <div class="grid grid-cols-2 gap-2 mb-8">
                        <button v-for="option in currentQuestion.options" :key="option.text"
                            v-if="!currentQuestion.isImageAlternative"
                            class="border border-white rounded-2xl h-[72px] w-full text-white font-bold leading-none"
                            :class="{ 'selected-answer': selectedOption === option.text }" @click="saveAnswer(option)"
                            v-html="option.text">
                        </button>

                        <button v-for="option in currentQuestion.options" :key="option.text"
                            v-if="currentQuestion.isImageAlternative"
                            class="border border-white rounded-2xl h-[72px] w-full text-white font-bold leading-none flex items-center justify-center"
                            :class="{ 'selected-answer': selectedOption === option.text }" @click="saveAnswer(option)"
                            v-html="option.imageAlternative">
                        </button>
                    </div>
                    <Button :disabled="selectedOption === false" text="Gå till nästa fråga"
                        @click="nextQuestion"></Button>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onUnmounted, onMounted, ref, computed } from 'vue'
import { questions } from '../data/questions'
import ProgressBar from './ProgressBar.vue'
import Timer from './Timer.vue'
import Button from './Button.vue'


export default {
    name: 'QuestionScreen',
    components: {
        ProgressBar,
        Timer,
        Button,
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        const showQuestion = ref(true)
        const currentQuestionIndex = ref(0)
        const currentQuestion = computed(() => questions[currentQuestionIndex.value])
        const hasAnswered = ref(false)
        const progress = ref(0);
        const startTime = ref(Date.now());
        const selectedOption = ref(false)
        const elapsedTime = computed(() => store.getters.elapsedTime)
        let timerInterval = null;


        function toggleQuestionVisibility() {
            showQuestion.value = !showQuestion.value;
        }


        function startTimer() {
            timerInterval = setInterval(() => {
                const currentTime = new Date(Date.now() - startTime.value);
                const hours = currentTime.getUTCHours().toString().padStart(2, '0');
                const minutes = currentTime.getUTCMinutes().toString().padStart(2, '0');
                const seconds = currentTime.getUTCSeconds().toString().padStart(2, '0');
                store.commit('elapsedTime', `${hours}:${minutes}:${seconds}`)
            }, 1000);
        }

        function stopTimer() {
            clearInterval(timerInterval);
            timerInterval = null;
        }

        onMounted(() => {
            startTimer();
        });

        onUnmounted(() => {
            stopTimer();
        });

        function updateProgress() {
            progress.value = currentQuestionIndex.value;
            console.log('Question ' + currentQuestionIndex.value + ' of ' + questions.length + ' answered');
        }

        function saveAnswer(option) {
            let temporaryAnswers = store.getters.answers;
            temporaryAnswers[currentQuestionIndex.value] = {
                questionId: currentQuestion.value.id,
                answer: option.text,
                isCorrect: option.isCorrect
            };
            store.commit('setAnswers', temporaryAnswers);
            selectedOption.value = option.text;
            hasAnswered.value = true;
        }

        function nextQuestion() {
            if (hasAnswered.value) {
                if (currentQuestionIndex.value < questions.length - 1) {
                    toggleQuestionVisibility();

                    setTimeout(() => {
                        toggleQuestionVisibility();
                    }, 150);

                    // Move to the next question only if the current one is answered
                    currentQuestionIndex.value++;
                    hasAnswered.value = false; // Reset the answer flag
                    selectedOption.value = false;
                    window.scrollTo(0, 0)
                    updateProgress(); // Update the progress for the next question
                } else {
                    // Handle the end of the quiz only if there are no more questions
                    stopTimer();
                    store.commit('setFinalScore', calculateScore());

                    // Potentially navigate to a results screen or display final results
                    router.push({ name: 'register' });
                }
            } else {
                // Optionally provide feedback that the current question must be answered
                alert("Please answer the current question before moving to the next.");
            }
        }

        function calculateScore() {
            const score = store.getters.answers.filter(a => a.isCorrect).length;
            return score;
        }

        return { currentQuestion, nextQuestion, saveAnswer, progress, questions, elapsedTime, hasAnswered, selectedOption, showQuestion };
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.selected-answer {
    @apply transition-colors duration-200 text-[#0F2337] bg-white;

    svg circle {
        @apply stroke-lightblue;
    }
}
</style>