<template>
    <input class="border border-white rounded-2xl py-3 px-4 bg-transparent text-white placeholder-white text-[18px]"
        type="text" :placeholder="placeholder" :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>
export default {
    name: 'TextInput',
    props: {
        placeholder: {
            type: String,
            default: 'Placeholder',
        },
        modelValue: {
            type: String,
            default: '',
        },
    }
}
</script>