<template>
    <div class="wrapper flex flex-col h-full pt-8">
        <img class="w-full max-w-[297px] mx-auto mb-6" :src="logo">

        <div class="text-center">
            <p class="text-center text-white text-[16px] leading-[1.6] mb-1.5">Ditt resultat:</p>
            <p v-if="!isCurrentUserFirst" class="text-[36px] leading-[1.1] font-bold text-white text-center">{{
                finalScore }}/{{
                    questions.length }}
            </p>
            <p v-if="isCurrentUserFirst"
                class="text-[36px] leading-[1.1] font-medium text-white text-center flex flex-row justify-center items-center gap-2">
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5002 5.5L7.00024 1M18.0561 14L1 13M15.5113 22.2321L5.00023 26" stroke="#FFF"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
                {{ finalScore }}/{{ questions.length }}
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5559 5.5L12.0559 1M1 14L18.0561 13M3.54482 22.2321L14.0559 26" stroke="#FFF"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </p>

            <p class="text-white text-[18px] leading-[1.6] font-medium mb-1.5">{{ elapsedTime }}</p>
            <p v-if="!isCurrentUserFirst" class="text-center text-white text-[18px] leading-[1.6] mb-8">Tyvärr räckte
                det
                inte hela vägen upp i toppen. Men du, ta en bit tårta så känns det nog snart bättre. Tack för att du är
                med och firar med oss!</p>
            <p v-if="isCurrentUserFirst" class="text-center text-white text-[18px] leading-[1.6] mb-8">WOW, jobbar du
                här? Håll tummarna att ingen är lika vass som du - högvinsten är inom räckhåll! Tack för att du är med
                och firar med oss!</p>
        </div>

        <div class="px-5 mb-6">
            <div class="bg-lighterblue rounded-2xl py-6 px-5">
                <p class="text-white text-[16px] leading-[1.6] mb-2">TOPP TIO</p>
                <ol class="list-decimal list-inside" v-if="leaderboard">
                    <li class="text-white text-[16px] leading-[1.6] mb-2 last:mb-0"
                        :class="{ 'text-[18px] leading-[1.6] font-medium': participant.isCurrentUser }"
                        v-for="participant in leaderboard" :key="participant.id">
                        {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>

    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { questions } from '../data/questions'

import Button from './Button.vue'

import logo from '@assets/img/logotype.png'

export default {
    name: 'ResultScreen',
    components: {
        Button,
    },
    computed: {
        logo() {
            return logo
        }
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const elapsedTime = computed(() => store.getters.elapsedTime)
        const finalScore = computed(() => store.getters.finalScore)
        const leaderboard = computed(() => store.getters.leaderboard)
        const isCurrentUserFirst = computed(() => store.getters.isCurrentUserFirst)

        onMounted(() => {
            if (store.getters.answers.length < questions.length) {
                console.log('User has not completed the quiz, return to frontpage')
                router.push({ name: 'start' })
            }
        })

        return {
            elapsedTime,
            finalScore,
            leaderboard,
            isCurrentUserFirst,
            questions
        }

    }
}
</script>
